<template>
  <header class="bg-white fixed inset-x-0 top-0 !z-[1000]">
    <div class="container flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 py-2.5 lg:py-4">
      <NuxtLink to="/" class="inline-block">
        <img src="~/assets/images/sa-logo.png" alt="" class="w-[280px]" />
      </NuxtLink>
      <LayoutFoodPantryQr />
    </div>
    <FpWarningModal
        v-if="openWarning"
        :open="openWarning"
        :new-title="'Warning'"
        :description="description"
        @update:open="closeModal"
    />
  </header>
</template>
<script setup lang="ts">
import {useRoute, useRouter} from "vue-router";
import {useQuery} from "@tanstack/vue-query";

const $route = useRoute();
const router = useRouter();
const id = $route.params.id as string; // Get the food pantry ID from route params
const interval = setInterval(() => {
  refetch();
  refetchConfig();
}, 10000);

const description = ref('');
const { refetch } = useQuery(['food-pantry', id], () => FoodPantry.getFoodPantryById(id), {
  onSuccess(data) {
    if (!data?.status) {
      description.value = 'This food pantry is currently inactive.  Please consult a Salvation Army representative for assistance.';
      openWarning.value = true;
      clearInterval(interval);
    }
  },
});

const {isLoading: isConfigLoading, data: dataConfig, refetch: refetchConfig} = useQuery(['food-config'], () => FoodPantry.getConfig(), {
  onSuccess(data) {
    if (!data?.active) {
      openWarning.value = true;
      description.value = 'Distribution of food assistance is currently inactive for this Salvation Army location. Please contact The Salvation Army for any additional information.';
      clearInterval(interval);
    }
  },
});

const openWarning = ref(false);
const closeModal = () => {
  openWarning.value = false;
  router.push('/')
};
</script>
